import React, {useEffect, useState} from "react";
import {Button, Checkbox, Input, Select, Space} from "antd";
import {Col, Container, Row, Table} from "reactstrap";
import {LoadingSpinner} from "../../../shared/LoadingSpinner";
import axios from "axios";
import {PopNotification} from "../../../shared/notification";
import {useCredentialStore} from "../../../hooks/useCredentialsStore";
import isNumeric from "antd/lib/_util/isNumeric";

const TenantAccess: React.FC = ({children}) => {
    const queryParams = new URLSearchParams(window.location.search)
    const id = queryParams.get('id')

    const {token} = useCredentialStore(({token}) => ({
        token
    }));

    const [loading, setLoading] = useState(false)

    const [tenant, setTenant] = useState({
        "id": 1,
        "name": 'Proton',
    })

    const [searchChargePointList, setSearchChargePointList] = useState<any[]>([]);
    const [assignedChargePointList, setAssignedChargePointList] = useState<any[]>([{
        'name': 'paid rate charger',
        'rate': 100,
        'selected': false
    }]);

    const [rate, setRate] = useState<string | undefined>();

    const onSearchChargePointByName = (value: string) => {
        if (value !== "" || value != null || value !== undefined) {
            setLoading(true);
            axios
                .get(`/api/chargePoints?page=0&pageSize=${9999}&textSearch=${value}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then((res) => {
                    setSearchChargePointList(res.data.data);
                })
                .catch((error) => {
                    PopNotification("error", error.response.data.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const onGetAssignedCharger = () => {
        setLoading(true);
        axios
            .get(`/api/tenant/${id}/chargePoint?page=0&pageSize=${9999}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                console.log(JSON.stringify(res.data))
                // @ts-ignore
                setAssignedChargePointList(res.data.map(x => {
                    return {...x, selected: false}
                }));
            })
            .catch((error) => {
                PopNotification("error", error.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onGetTenant = () => {
        setLoading(true);
        axios
            .get(`/api/tenant/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                setTenant(res.data.data);
            })
            .catch((error) => {
                PopNotification("error", error.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onAssignChargePointToTenant = (chargePointId: any) => {
        setLoading(true);
        axios
            .post(`/api/tenant/${id}/chargePoint`, {
                "chargePointId": chargePointId.id
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                onGetAssignedCharger()
            })
            .catch((error) => {
                PopNotification("error", error.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }


    useEffect(() => {
        // onGetTenant()
        onGetAssignedCharger()
    }, [])


    const assignChargePointToTenant = (chargePointId: any) => {
        console.log(chargePointId)
        console.log(assignedChargePointList)
        let assigned = assignedChargePoint(chargePointId.id);

        if (assigned) {
            return;
        }
        onAssignChargePointToTenant(chargePointId)
    };

    const assignedChargePoint = (chargePointId: any) => {
        return assignedChargePointList.map(x => x.chargePointId).includes(chargePointId);
    }


    const applyCheckAll = (e: any) => {
        setAssignedChargePointList(list => {
            return list.map(chargePoint => ({...chargePoint, 'selected': e.target.checked}))
        })
    };

    const applyCheck = (idx: number) => {
        setAssignedChargePointList(list => {
            let target = list[idx];
            console.log(target)
            let newTarget = {...target, 'selected': !target.selected}
            console.log(newTarget)
            return list.map((value, i) => {
                return i !== idx ? value : newTarget
            });
        })
    };

    const changeSelectedChargePointRate = (fee: any) => {
        let chargePointList = assignedChargePointList.filter(chargePoint => chargePoint.selected).map(chargePoint => chargePoint.chargePointId);

        onBulkUpdateFee(chargePointList, fee)
    };

    const onBulkUpdateFee = (chargePointList: string[], fee: any) => {
        setLoading(true);
        axios
            .put(`/api/tenant/${id}/chargePoint/batchUpdateFee`, {
                "chargePointIds": chargePointList,
                "fee": fee
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                onGetAssignedCharger()
            })
            .catch((error) => {
                PopNotification("error", error.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const removeChargePointAccess = (chargePointId: string) => {
        setLoading(true);
        axios
            .delete(`/api/tenant/${id}/chargePoint/${chargePointId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                onGetAssignedCharger()
            })
            .catch((error) => {
                PopNotification("error", error.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return <>
        <div>
            <span className='page-title'>Tenant Access</span>
            <div>
                <span className='heading-page'>Tenant: {tenant.name}</span>
            </div>

            <div>
                <Container fluid={true} style={{marginTop: 20}}>

                    <Space direction={"vertical"} style={{width: '100%'}}>
                        <Input
                            placeholder="input search text"
                            allowClear
                            onChange={(e) => {
                                onSearchChargePointByName(e.target.value)
                            }}
                            size='large'
                        />
                    </Space>

                    <Row md={12} style={{marginTop: 20}}>
                        <Col md={12} className='card-datavisulization'
                             style={{textAlign: "left", padding: 20, height: "auto"}}>
                            {
                                loading ? (
                                    <LoadingSpinner/>
                                ) : (
                                    <Table bordered responsive>
                                        <thead>
                                        <tr>
                                            <th>
                                                No
                                            </th>
                                            <th>
                                                Name
                                            </th>
                                            <th>
                                                Action
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            searchChargePointList?.map((chargePoint, index) => (
                                                <tr>
                                                    <td>
                                                        {index + 1}
                                                    </td>
                                                    <td>
                                                        {chargePoint.name}
                                                    </td>
                                                    <td style={{textAlign: "center"}}>
                                                        <Button block type="primary" htmlType="button"
                                                                className={"full-assign-button"}
                                                                disabled={assignedChargePoint(chargePoint.id.id)}
                                                                onClick={() => {
                                                                    assignChargePointToTenant(chargePoint.id)
                                                                }}>
                                                            Assign Access
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </Table>
                                )
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <div>
                    <span className='heading-page'> Access</span>
                </div>
                <Space direction={"horizontal"} style={{width: '100%'}}>
                    <Input
                        placeholder="input 0 - 1"
                        value={rate}
                        onChange={(e) => {
                            let value = e.target.value;
                            if (isNumeric(value) || '' === value) {
                                setRate(value)
                            }
                        }}
                        size='large'
                    />
                    <Button onClick={x => changeSelectedChargePointRate(rate)}>
                        Change
                    </Button>
                </Space>

                <Row md={12} style={{marginTop: 20}}>
                    <Col md={12} className='card-datavisulization'
                         style={{textAlign: "left", padding: 20, height: "auto"}}>
                        {
                            loading ? (
                                <LoadingSpinner/>
                            ) : (
                                <Table bordered responsive>
                                    <thead>
                                    <tr>
                                        <th>
                                            <Checkbox onChange={applyCheckAll}></Checkbox>
                                        </th>
                                        <th>
                                            Name
                                        </th>
                                        <th>
                                            Rate (%)
                                        </th>
                                        <th>
                                            Action
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        assignedChargePointList?.map((chargePoint, index) => (
                                            <tr>
                                                <td>
                                                    <Checkbox checked={chargePoint.selected}
                                                              onChange={e => applyCheck(index)}/>
                                                </td>
                                                <td>
                                                    {chargePoint.chargePointName}
                                                </td>
                                                <td>
                                                    {+chargePoint.fee * 100}
                                                </td>
                                                <td>
                                                    <Button className="remove-button" onClick={() => {
                                                        removeChargePointAccess(chargePoint.chargePointId)
                                                    }}>
                                                        Remove
                                                    </Button>
                                                </td>

                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </Table>
                            )
                        }
                    </Col>
                </Row>
            </div>


        </div>
    </>
}

export default TenantAccess;