import React, {useState} from "react";
import {Button, Form, Input, Select} from "antd";
import {Option} from "antd/es/mentions";

const AddTenant = ({ onOk = (tenant) => {}}) => {
    let [form] = Form.useForm();
    const [selectedAuthMethod, setSelectedAuthMethod] = useState("RAW")

    return <>
        <span className='heading-page'> Add Tenant</span>
        <Form form={form} onFinish={(tenant) => {
            onOk(tenant)
            form.resetFields();
        }}>
            <span>
                Name
            </span>
            <Form.Item
                name="name"
                rules={[{required: true, message: 'Please input name!'}]}
            >
                <Input
                    placeholder="Name"
                    className='input-box'
                />
            </Form.Item>
            <span>
                Email
            </span>
            <Form.Item
                name="email"
                rules={[{required: true, message: 'Please input email!'}]}
            >
                <Input
                    placeholder="Email"
                    className='input-box'
                />
            </Form.Item>
            <span>
                Auth Method
            </span>
            <Form.Item
                name="authMethod"
                rules={[{required: true, message: 'Please input auth method!'}]}
            >
               <Select onChange={(val) => setSelectedAuthMethod(val)}>
                   <Select.Option key="RAW">RAW</Select.Option>
                   <Select.Option key="BASE64">BASE64</Select.Option>
               </Select>
            </Form.Item>
            <Button htmlType='submit'>
                OK
            </Button>
        </Form>
    </>
}

export default AddTenant;