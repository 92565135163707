import React, {useEffect, useState} from "react";
import {Button, Form, Modal} from "antd";
import axios from "axios";
import {useCredentialStore} from "../../../hooks/useCredentialsStore";
import {PopNotification} from "../../../shared/notification";
import AddTenant from "../AddTenant";
import OcpiRegister from "../OcpiRegister";

const TenantDetails: React.FC = ({children}) => {
    const queryParams = new URLSearchParams(window.location.search)
    const id = queryParams.get('id')

    const {token} = useCredentialStore(({token}) => ({
        token
    }));

    const [loading, setLoading] = useState('false')
    const [ocpiRegisterVisible, setOcpiRegisterVisible] = useState(false);

    const [tenant, setTenant] = useState({
        "id": 1,
        "name": "Proton",
        "email": "proton@proton.com",
        "status": 1,
        "token": "text",
        "baseUrl": "http://test.com/api/ocpi/xx",
        "authMethod": "RAW",
        "ocpiRegistered": false
    })

    useEffect(() => {
        loadTenantDetails();
    }, []);

    const loadTenantDetails = () =>  {
        axios.get(`/api/tenant/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            setTenant(res.data)
        }).catch((err) => {
            PopNotification("error", err?.response?.data?.message || `Something went wrong, ${err.toString()}`);
        }).finally(() => {
        })
    }


    const refreshToken = () =>  {
        axios.post(`/api/tenant/refreshToken`, {id} ,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            loadTenantDetails()
        }).catch((err) => {
            PopNotification("error", err?.response?.data?.message || `Something went wrong, ${err.toString()}`);
        }).finally(() => {
        })
    }


    const handleOcpiRegistration = (registrationDetails: any) => {
        axios.post(`/api/tenant/${tenant.id}/ocpi/registration`, {
           ...registrationDetails
        } ,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            loadTenantDetails()
        }).catch((err) => {
            PopNotification("error", err?.response?.data?.message || `Something went wrong, ${err.toString()}`);
        }).finally(() => {
        })
    }

    return <>
        <div>
            <span className='page-title'>Tenant Details</span>
            <div>
                <span className='heading-page'>Basic Details</span>
                <span className='id-css'>name: </span>
                <span>{tenant.name}</span>
                <br/>
                <span className='id-css'>email: </span>
                <span>{tenant.email}</span>
                <br/>
            </div>

            <div>
                <span className='heading-page'>OCPI</span>
                <span className='id-css'>credentials: </span>
                <span>{tenant.token}</span>
                <br/>
                <span className='id-css'>url: </span>
                <span>{tenant.baseUrl}</span>
                <br/>
                <span className='id-css'>auth method: </span>
                <span>{tenant.authMethod}</span>
                <br/>
                <span className='id-css'>ocpi registered: </span>
                <span>{tenant.ocpiRegistered ? "Yes" : "No"}</span>
            </div>
            <br/>

            <div style={{display: "flex", gap: "10px"}}>
                <Button onClick={() => {
                    refreshToken()}}>Refresh Key</Button>
                {
                    !tenant.ocpiRegistered &&
                    <Button onClick={() => {setOcpiRegisterVisible(true)}}>Register Ocpi</Button>
                }
            </div>

        </div>

        <Modal
            visible={ocpiRegisterVisible}
            footer={[]}
            onCancel={() => {
                setOcpiRegisterVisible(false)
            }}
        >
            <OcpiRegister onOk={(registrationDetails) => {
                handleOcpiRegistration(registrationDetails)
                setOcpiRegisterVisible(false)
            }}/>
        </Modal>
    </>
}

export default TenantDetails;