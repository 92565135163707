import React, {useEffect, useRef, useState} from "react";
import {Col, Container, Row} from "reactstrap";
import {Button, Modal, Select, Table} from "antd";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {transactionResponse} from "../../../types/admin";
import {PopNotification} from "../../../shared/notification";
import {useCredentialStore} from "../../../hooks/useCredentialsStore";
import AddTenant from "../AddTenant";
import addTenant from "../AddTenant";

const TenantList: React.FC = ({children}) => {
    const navigate = useNavigate()
    const {token} = useCredentialStore(({token}) => ({
        token
    }));

    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true, //this line allows the dropdown to show to choose the number of items per page
        pageSizeOptions: ['10', '20', '50', '100', '200', '500', '1000'] //set the options of items per page selecting (pagination dropdown)
    });

    const [tenant, setTenant] = useState([{
        "id": 1,
        "name": 'Proton',
        "email": 'proton@proton.com',
        "status": 'ACTIVE'
    }])

    const [addTenantVisible, setAddTenantVisible] = useState(false)

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            render: (text: string) => <span>{text ?? "-"}</span>
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text: string) => <span>{text ?? "-"}</span>
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (text: string) => <span>{text ?? "-"}</span>
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status: string) => <span>{status === "ACTIVE" ? 'Active' : 'Inactive'}</span>
        },
        {
            title: 'Actions',
            dataIndex: 'status',
            key: 'actions',
            render: (text: string, record: any) => {
                return <div>
                    <Button onClick={() => {
                        navigate(`/tenant/details?id=${record.id}`, {replace: false})
                    }}>Details</Button>
                    <Button onClick={() => {
                        navigate(`/tenant/access?id=${record.id}`, {replace: false})
                    }}>Access</Button>
                    {
                        record.status === "ACTIVE" ?
                            <Button onClick={() => {
                                handleDeactivateTenant(record.id)
                            }}>
                                Deactivate
                            </Button> :
                            <Button onClick={() => {
                                handleActivateTenant(record.id)
                            }}>
                                Activate
                            </Button>
                    }
                </div>
            }
        }
    ]

    useEffect(() => {
        loadAllTenant(pagination.current, pagination.pageSize)
    }, []);

    const loadAllTenant = (page: number, pageSize: number) => {
        setLoading(true);
        axios.get(`/api/tenant?page=${page - 1}&pageSize=${pageSize}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            const response = res.data;
            setTenant(response.data);
            setPagination({
                ...pagination,
                current: page,
                pageSize: pageSize,
                total: response.totalElements
            })
        }).catch((err) => {
            PopNotification("error", err?.response?.data?.message || `Something went wrong, ${err.toString()}`);
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleDeactivateTenant = (id: string) => {
        setLoading(true);
        axios.post('/api/tenant/deactivate', {id}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            loadAllTenant(pagination.current, pagination.pageSize)
        }).catch((err) => {
            PopNotification("error", err?.response?.data?.message || `Something went wrong, ${err.toString()}`);
        }).finally(() => {
            setLoading(false);
        })
    };

    const handleActivateTenant = (id: string) => {
        setLoading(true);
        axios.post('/api/tenant/activate', {id}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            loadAllTenant(pagination.current, pagination.pageSize)
        }).catch((err) => {
            PopNotification("error", err?.response?.data?.message || `Something went wrong, ${err.toString()}`);
        }).finally(() => {
            setLoading(false);
        })
    };

    const handleTableChange = (pagination: any) => {
        loadAllTenant(pagination.current, pagination.pageSize)
    };

    function handleAddTenant(tenant: any) {
        setLoading(true);
        axios.post('/api/tenant', {...tenant}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            loadAllTenant(pagination.current, pagination.pageSize)
        }).catch((err) => {
            PopNotification("error", err?.response?.data?.message || `Something went wrong, ${err.toString()}`);
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <div>
            <Container fluid={true} style={{marginTop: 20}}>
                <Row style={{marginBottom: 20}}>
                    <Col md={8}>
                        <span className='page-title' style={{marginBottom: 0}}>Tenant | Tenant List</span>
                    </Col>
                    <Col md={4} style={{textAlign: "right"}}>
                        <span onClick={() => {
                            setAddTenantVisible(true)
                        }} style={{
                            backgroundColor: "#39B64B",
                            padding: "5px 15px",
                            cursor: "pointer",
                            color: "white",
                            borderRadius: 7,
                            display: "inline-block",
                            textTransform: "uppercase",
                            marginRight: 10
                        }}>
                            + new tenant
                        </span>
                    </Col>
                </Row>
                <Row md={12}>
                    <Col md={12} className='card-datavisulization'
                         style={{textAlign: "left", padding: 20, height: "80vh"}}>
                        <Table
                            columns={columns}
                            dataSource={tenant}
                            loading={loading}
                            pagination={pagination}
                            onChange={handleTableChange}
                        />
                    </Col>
                </Row>
            </Container>
            <Modal
                visible={addTenantVisible}
                footer={[]}
                onCancel={() => {
                    setAddTenantVisible(false)
                }}
            >
                <AddTenant onOk={(tenant) => {
                    handleAddTenant(tenant)
                    setAddTenantVisible(false)
                }}/>
            </Modal>
        </div>
    )
}

export default TenantList;