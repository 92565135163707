import React from "react";
import {Button, Form, Input} from "antd";

const OcpiRegister = ({ onOk = (registrationDetails) => {}}) => {
    let [form] = Form.useForm();

    return <>
        <span className='heading-page'> Register Ocpi</span>
        <Form form={form} onFinish={(registrationDetails) => {
            onOk(registrationDetails)
            form.resetFields();
        }}>
            <span>
                Token
            </span>
            <Form.Item
                name="token"
                rules={[{required: true, message: 'Please input token!'}]}
            >
                <Input
                    placeholder="Token"
                    className='input-box'
                />
            </Form.Item>
            <span>
                Url
            </span>
            <Form.Item
                name="url"
                rules={[{required: true, message: 'Please input url!'}]}
            >
                <Input
                    placeholder="Url"
                    className='input-box'
                />
            </Form.Item>
            <Button htmlType='submit'>
                OK
            </Button>
        </Form>
    </>
}

export default OcpiRegister;