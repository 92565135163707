import {Col, Container, Input, PopoverBody, PopoverHeader, Row, UncontrolledPopover} from "reactstrap";
import {Button, Image, Space} from "antd";
import {FunnelPlotOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import ReactDOM from 'react-dom';
import {Line, Column} from '@ant-design/plots';
import {LineConfig} from "@ant-design/plots/es/components/line";
import "./index.css"
import {ColumnConfig} from "@ant-design/plots/es/components/column";
import axios from "axios";
import {
    chargerSummary, co2EmissionBarChartProps,
    dailyMonthlyData,
    getChargePointBasedOnTextSearchResponse,
    graphData, transactionBarChartProps, transactionByEnergyPieChartProps
} from "../../../types/admin";
import {PopNotification} from "../../../shared/notification";
import {useCredentialStore} from "../../../hooks/useCredentialsStore";
import moment from "moment";
import {LineChart} from "../../../components/charts/LineChart";
import Chart from "react-apexcharts";

export const ChargerReport = () => {

    const queryParams = new URLSearchParams(window.location.search)
    const id = queryParams.get("chargerId")
    const chargePointId = queryParams.get("chargePointId")
    const {token} = useCredentialStore(({token}) => ({
        token
    }));
    const [yearlySummary, setYearlySummary] = useState<chargerSummary>({
        totalTransaction: 0,
        totalAmount: 0,
        totalActualAmount: 0,
        totalDuration: 0,
        averageDuration: 0,
        totalEnergy: 0,
        averageEnergy: 0,
        co2EmissionReduced: 0
    })
    const [monthlySummary, setMonthlySummary] = useState<chargerSummary>({
        totalTransaction: 0,
        totalAmount: 0,
        totalActualAmount: 0,
        totalDuration: 0,
        averageDuration: 0,
        totalEnergy: 0,
        averageEnergy: 0,
        co2EmissionReduced: 0
    })
    const [dailyTransactionData, setDailyTransactionData] = useState<number[]>([])
    const [dailyEnergyData, setDailyEnergyData] = useState<number[]>([])
    const [dailyEarningsData, setDailyEarningsData] = useState<number[]>([])
    const [dailyActualEarningsData, setDailyActualEarningsData] = useState<number[]>([])

    const [monthlyTransactionData, setMonthlyTransactionData] = useState<number[]>([])
    const [monthlyEnergyData, setMonthlyEnergyData] = useState<number[]>([])
    const [monthlyEarningsData, setMonthlyEarningsData] = useState<number[]>([])
    const [monthlyActualEarningsData, setMonthlyActualEarningsData] = useState<number[]>([])

    const [selectedYear, setSelectedYear] = useState<number>(moment().year())
    const [selectedMonth, setSelectedMonth] = useState<number>(moment().month() + 1)

    const [weeklyTransactionData, setWeeklyTransactionData] = useState<number[]>([])
    const [yearlyCo2EmissionReducedData, setYearlyCo2EmissionReducedData] = useState<number[]>([])
    const [transactionByEnergyLabel, setTransactionByEnergyLabel] = useState<string[]>([])
    const [transactionByEnergyData, setTransactionByEnergyData] = useState<number[]>([])
    const [transactionByMaxPowerLabel, setTransactionByMaxPowerLabel] = useState<string[]>([])
    const [transactionByMaxPowerData, setTransactionByMaxPowerData] = useState<number[]>([])

    const onGetTransactionByMaxPowerData = () => {
        axios.get(`/api/v2/report/charger/${id}/transactionByPower`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            const response: transactionByEnergyPieChartProps = res.data;
            setTransactionByMaxPowerData(response.map((item) => {
                return item.count
            }))
            setTransactionByMaxPowerLabel(response.map((item) => {
                return item.label
            }))
        }).catch((err) => {
            PopNotification("error", err?.response?.data?.message || `Something went wrong, ${err.toString()}`);
        })
    }

    const onGetTransactionByEnergyData = () => {
        axios.get(`/api/v2/report/charger/${id}/transactionByEnergy`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            const response: transactionByEnergyPieChartProps = res.data;
            setTransactionByEnergyData(response.map((item) => {
                return item.count
            }))
            setTransactionByEnergyLabel(response.map((item) => {
                return item.label
            }))
        }).catch((err) => {
            PopNotification("error", err?.response?.data?.message || `Something went wrong, ${err.toString()}`);
        })
    }

    const onGetYearlyCo2EmissionReducedData = () => {
        axios.get(`/api/v2/report/charger/${id}/monthlyReduceCO2BarChart?year=${selectedYear}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            const response: co2EmissionBarChartProps = res.data;
            setYearlyCo2EmissionReducedData(response.map((item) => {
                return item.co2Reduced
            }))
        }).catch((err) => {
            PopNotification("error", err?.response?.data?.message || `Something went wrong, ${err.toString()}`);
        })
    }

    const onGetWeeklyTransactionData = () => {
        axios.get(`/api/v2/report/charger/${id}/dailyTransactionBarChart`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            const response: transactionBarChartProps = res.data;
            setWeeklyTransactionData(response.map((item) => {
                return item.transaction
            }))
        }).catch((err) => {
            PopNotification("error", err?.response?.data?.message || `Something went wrong, ${err.toString()}`);
        })
    }

    const onGetChargerYearlySummary = () => {
        axios.get(`/api/v2/report/charger/${id}/summary/yearly?year=${selectedYear}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            const response: chargerSummary = res.data;
            setYearlySummary(response);
        }).catch((err) => {
            PopNotification("error", err?.response?.data?.message || `Something went wrong, ${err.toString()}`);
        })
    }

    const onGetChargerMonthlySummary = () => {
        axios.get(`/api/v2/report/charger/${id}/summary/monthly?year=${selectedYear}&month=${selectedMonth}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            const response: chargerSummary = res.data;
            setMonthlySummary(response);
        }).catch((err) => {
            PopNotification("error", err?.response?.data?.message || `Something went wrong, ${err.toString()}`);
        })
    }

    const onGetDailySummary = () => {
        axios.get(`/api/v2/report/charger/${id}/dailyReport?month=${selectedMonth}&year=${selectedYear}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            const response: dailyMonthlyData[] = res.data
            setDailyTransactionData(response.map((item) => {
                return item.transaction
            }))
            setDailyEnergyData(response.map((item) => {
                return item.energy
            }))
            setDailyEarningsData(response.map((item) => {
                return item.earnings
            }))
            setDailyActualEarningsData(response.map((item) => {
                return item.actualEarnings
            }))
        }).catch((err) => {
            PopNotification("error", err?.response?.data?.message || `Something went wrong, ${err.toString()}`);
        })
    }

    const onGetMonthlySummary = () => {
        axios.get(`/api/v2/report/charger/${id}/monthlyReport?year=${selectedYear}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            const response: dailyMonthlyData[] = res.data
            setMonthlyTransactionData(response.map((item) => {
                return item.transaction
            }))
            setMonthlyEnergyData(response.map((item) => {
                return item.energy
            }))
            setMonthlyEarningsData(response.map((item) => {
                return item.earnings
            }))
            setMonthlyActualEarningsData(response.map((item) => {
                return item.actualEarnings
            }))
        }).catch((err) => {
            PopNotification("error", err?.response?.data?.message || `Something went wrong, ${err.toString()}`);
        })
    }

    useEffect(() => {
        if (id) {

            onGetChargerYearlySummary()
            onGetChargerMonthlySummary()
            onGetWeeklyTransactionData()
            onGetYearlyCo2EmissionReducedData()
            onGetTransactionByEnergyData()
            onGetTransactionByMaxPowerData()
        }
    }, [id]);

    useEffect(() => {
        onGetChargerYearlySummary()
        onGetChargerMonthlySummary()

        onGetDailySummary()
        onGetMonthlySummary()

        onGetWeeklyTransactionData()
        onGetYearlyCo2EmissionReducedData()
        onGetTransactionByEnergyData()
        onGetTransactionByMaxPowerData()

    }, [selectedYear, selectedMonth])

    return (
        <>
            <Container fluid={true} style={{paddingLeft: 0, paddingRight: 0, marginTop: 20}}>
                <Row className='card-datavisulization'
                     style={{height: "auto", marginTop: 20, textAlign: "left", marginLeft: 0, marginRight: 0}}>
                    <Col md={4}>
                        <span style={{display: "flex", marginTop: 5, alignItems: "center"}}>
                                    Yearly Summary
                                </span>
                        <span style={{display: "flex", marginTop: 20, alignItems: "center"}}>
                                    <span>
                                        <Image
                                            src={require("../../../assets/images/dollar.png")}
                                            preview={false}
                                            style={{height: 20, width: "100%"}}
                                        />
                                    </span>
                                    <span style={{display: 'inline-block', marginLeft: 10}}>Total Transactions: <span
                                        style={{fontWeight: "bold"}}>{yearlySummary?.totalTransaction || 0} times</span>
                                    </span>
                                </span>
                        <span style={{display: "flex", marginTop: 5, alignItems: "center"}}>
                                    <span>
                                        <Image
                                            src={require("../../../assets/images/earn.png")}
                                            preview={false}
                                            style={{height: 20, width: "100%"}}
                                        />
                                    </span>
                                    <span style={{display: 'inline-block', marginLeft: 10}}>Total Amount: <span
                                        style={{fontWeight: "bold"}}>RM {yearlySummary?.totalAmount?.toFixed(2) || 0}</span></span>
                                </span>
                        <span style={{display: "flex", marginTop: 5, alignItems: "center"}}>
                                    <span>
                                        <Image
                                            src={require("../../../assets/images/earn.png")}
                                            preview={false}
                                            style={{height: 20, width: "100%"}}
                                        />
                                    </span>
                                    <span style={{display: 'inline-block', marginLeft: 10}}>Total Actual Amount: <span
                                        style={{fontWeight: "bold"}}>RM {yearlySummary?.totalActualAmount?.toFixed(2) || 0}</span></span>
                                </span>
                        <span style={{display: "flex", marginTop: 5, alignItems: "center"}}>
                                    <span>
                                        <Image
                                            src={require("../../../assets/images/co2.png")}
                                            preview={false}
                                            style={{height: 20, width: "100%"}}
                                        />
                                    </span>
                                    <span style={{display: 'inline-block', marginLeft: 10}}>Reduced CO2 emission: <span
                                        style={{fontWeight: "bold"}}>{yearlySummary?.co2EmissionReduced?.toFixed(2) || 0} kg</span></span>
                                </span>
                        <span style={{display: "flex", marginTop: 5, alignItems: "center"}}>
                                    <span>
                                        <Image
                                            src={require("../../../assets/images/time.png")}
                                            preview={false}
                                            style={{height: 30, width: "100%"}}
                                        />
                                    </span>
                                    <span>
                                        <span style={{display: 'block', marginLeft: 10}}>Total duration: <span
                                            style={{fontWeight: "bold"}}>{moment(yearlySummary?.totalDuration).format('mm')} minutes</span></span>
                                        <span style={{display: 'inline-block', marginLeft: 10}}>Average duration: <span
                                            style={{fontWeight: "bold"}}>{moment(yearlySummary?.averageDuration).format('mm')} minutes</span></span>
                                    </span>
                                </span>
                        <span style={{display: "flex", marginTop: 5, alignItems: "center"}}>
                                    <span>
                                        <Image
                                            src={require("../../../assets/images/startstop.png")}
                                            preview={false}
                                            style={{height: 22, width: "100%"}}
                                        />
                                    </span>
                                    <span>
                                        <span style={{display: 'block', marginLeft: 10}}>Total energy: <span
                                            style={{fontWeight: "bold"}}>{yearlySummary?.totalEnergy || 0} kWh</span></span>
                                        <span style={{display: 'block', marginLeft: 10}}>Average energy: <span
                                            style={{fontWeight: "bold"}}>{yearlySummary?.averageEnergy || 0} kWh</span></span>
                                    </span>
                                </span>
                    </Col>
                    <Col md={4}>
                        <span style={{display: "flex", marginTop: 5, alignItems: "center"}}>
                                    Monthly Summary
                                </span>
                        <span style={{display: "flex", marginTop: 20, alignItems: "center"}}>
                                    <span>
                                        <Image
                                            src={require("../../../assets/images/dollar.png")}
                                            preview={false}
                                            style={{height: 20, width: "100%"}}
                                        />
                                    </span>
                                    <span style={{display: 'inline-block', marginLeft: 10}}>Total Transactions: <span
                                        style={{fontWeight: "bold"}}>{monthlySummary?.totalTransaction || 0} times</span>
                                    </span>
                                </span>
                        <span style={{display: "flex", marginTop: 5, alignItems: "center"}}>
                                    <span>
                                        <Image
                                            src={require("../../../assets/images/earn.png")}
                                            preview={false}
                                            style={{height: 20, width: "100%"}}
                                        />
                                    </span>
                                    <span style={{display: 'inline-block', marginLeft: 10}}>Total Amount: <span
                                        style={{fontWeight: "bold"}}>RM {monthlySummary?.totalAmount?.toFixed(2) || 0}</span></span>
                                </span>
                        <span style={{display: "flex", marginTop: 5, alignItems: "center"}}>
                                    <span>
                                        <Image
                                            src={require("../../../assets/images/earn.png")}
                                            preview={false}
                                            style={{height: 20, width: "100%"}}
                                        />
                                    </span>
                                    <span style={{display: 'inline-block', marginLeft: 10}}>Total Actual Amount: <span
                                        style={{fontWeight: "bold"}}>RM {monthlySummary?.totalActualAmount?.toFixed(2) || 0}</span></span>
                                </span>
                        <span style={{display: "flex", marginTop: 5, alignItems: "center"}}>
                                    <span>
                                        <Image
                                            src={require("../../../assets/images/co2.png")}
                                            preview={false}
                                            style={{height: 20, width: "100%"}}
                                        />
                                    </span>
                                    <span style={{display: 'inline-block', marginLeft: 10}}>Reduced CO2 emission: <span
                                        style={{fontWeight: "bold"}}>{monthlySummary?.co2EmissionReduced || 0} kg</span></span>
                                </span>
                        <span style={{display: "flex", marginTop: 5, alignItems: "center"}}>
                                    <span>
                                        <Image
                                            src={require("../../../assets/images/time.png")}
                                            preview={false}
                                            style={{height: 30, width: "100%"}}
                                        />
                                    </span>
                                    <span>
                                        <span style={{display: 'block', marginLeft: 10}}>Total duration: <span
                                            style={{fontWeight: "bold"}}>{moment(monthlySummary?.totalDuration).format('mm')} minutes</span></span>
                                        <span style={{display: 'inline-block', marginLeft: 10}}>Average duration: <span
                                            style={{fontWeight: "bold"}}>{moment(monthlySummary?.averageDuration).format('mm')} minutes</span></span>
                                    </span>
                                </span>
                        <span style={{display: "flex", marginTop: 5, alignItems: "center"}}>
                                    <span>
                                        <Image
                                            src={require("../../../assets/images/startstop.png")}
                                            preview={false}
                                            style={{height: 22, width: "100%"}}
                                        />
                                    </span>
                                    <span>
                                        <span style={{display: 'block', marginLeft: 10}}>Total energy: <span
                                            style={{fontWeight: "bold"}}>{monthlySummary?.totalEnergy || 0} kWh</span></span>
                                        <span style={{display: 'block', marginLeft: 10}}>Average energy: <span
                                            style={{fontWeight: "bold"}}>{monthlySummary?.averageEnergy || 0} kWh</span></span>
                                    </span>
                                </span>
                    </Col>
                    <Col md={4}>
                        <div style={{padding: 20, position: "relative"}}>
                            <Image
                                src={"https://images.unsplash.com/photo-1593642532400-2682810df593?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80"}
                                style={{height: 200}}
                            />
                            {/*<span id="PopoverLegacy1" style={{*/}
                            {/*    display: "block",*/}
                            {/*    position: "absolute",*/}
                            {/*    top: 10,*/}
                            {/*    right: 10,*/}
                            {/*    cursor: "pointer"*/}
                            {/*}}>*/}
                            {/*            <FunnelPlotOutlined style={{fontSize: 20}}/>*/}
                            {/*        </span>*/}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <h3 style={{textAlign: 'center'}}>{ moment(selectedMonth, 'M').format('MMMM') } {selectedYear}</h3>
                </Row>
                <Row>
                    <Col md={1}>
                        <Button style={{marginTop: '15vh', height: '8vh', width: '3vw'}}
                                onClick={() => setSelectedMonth(selectedMonth > 1 ? selectedMonth - 1 : 1)}>{`<`}</Button>
                    </Col>
                    <Col md={10}>
                        <div className='card-graph-visualization'>
                            <Chart options={{
                                legend: {
                                    tooltipHoverFormatter: function (val: any, opts: any) {
                                        return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
                                    }
                                },
                                markers: {
                                    size: 0,
                                    hover: {
                                        sizeOffset: 6
                                    }
                                },
                                xaxis: {
                                    categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
                                },
                                tooltip: {
                                    y: [
                                        {
                                            title: {
                                                formatter: function (val: any) {
                                                    return val
                                                }
                                            }
                                        },
                                        {
                                            title: {
                                                formatter: function (val: any) {
                                                    return val
                                                }
                                            }
                                        },
                                        {
                                            title: {
                                                formatter: function (val: any) {
                                                    return val;
                                                }
                                            }
                                        },
                                        {
                                            title: {
                                                formatter: function (val: any) {
                                                    return val;
                                                }
                                            }
                                        }
                                    ]
                                },
                                grid: {
                                    borderColor: '#f1f1f1',
                                }
                            }}
                                   series={[
                                       {
                                           name: "Transaction",
                                           data: dailyTransactionData
                                       },
                                       {
                                           name: "Energy",
                                           data: dailyEnergyData
                                       },
                                       {
                                           name: "Earnings",
                                           data: dailyEarningsData
                                       },
                                       {
                                           name: "Actual Earnings",
                                           data: dailyActualEarningsData
                                       }
                                   ]} type="line" height={350}/>
                        </div>
                    </Col>
                    <Col md={1}>
                        <Button style={{marginTop: '15vh', height: '8vh', width: '3vw'}}
                                onClick={() => setSelectedMonth(selectedMonth < 12 ? selectedMonth + 1 : 12)}>{`>`}</Button>
                    </Col>
                </Row>
                <Row>
                    <h3 style={{textAlign: 'center'}}>Year {selectedYear}</h3>
                </Row>
                <Row>
                    <Col md={1}>
                        <Button style={{marginTop: '15vh', height: '8vh', width: '3vw'}}
                                onClick={() => setSelectedYear(selectedYear - 1)}>{`<`}</Button>
                    </Col>
                    <Col md={10}>
                        <div className='card-graph-visualization'>
                            <Chart options={{
                                legend: {
                                    tooltipHoverFormatter: function (val: any, opts: any) {
                                        return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
                                    }
                                },
                                markers: {
                                    size: 0,
                                    hover: {
                                        sizeOffset: 6
                                    }
                                },
                                xaxis: {
                                    categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
                                },
                                tooltip: {
                                    y: [
                                        {
                                            title: {
                                                formatter: function (val: any) {
                                                    return val
                                                }
                                            }
                                        },
                                        {
                                            title: {
                                                formatter: function (val: any) {
                                                    return val
                                                }
                                            }
                                        },
                                        {
                                            title: {
                                                formatter: function (val: any) {
                                                    return val;
                                                }
                                            }
                                        },
                                        {
                                            title: {
                                                formatter: function (val: any) {
                                                    return val;
                                                }
                                            }
                                        }
                                    ]
                                },
                                grid: {
                                    borderColor: '#f1f1f1',
                                }
                            }}
                                   series={[
                                       {
                                           name: "Transaction",
                                           data: monthlyTransactionData
                                       },
                                       {
                                           name: "Energy",
                                           data: monthlyEnergyData
                                       },
                                       {
                                           name: "Earnings",
                                           data: monthlyEarningsData
                                       },
                                       {
                                           name: "Actual Earnings",
                                           data: monthlyActualEarningsData
                                       }
                                   ]} type="line" height={350}/>
                        </div>
                    </Col>
                    <Col md={1}>
                        <Button style={{marginTop: '15vh', height: '8vh', width: '3vw'}}
                                onClick={() => setSelectedYear(selectedYear + 1)}>{`>`}</Button>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <h3>Weekly Transaction</h3>
                        <div className='card-graph-visualization'>
                            {
                                weeklyTransactionData.length > 0 ? (
                                    <Chart options={{
                                        legend: {
                                            tooltipHoverFormatter: function (val: any, opts: any) {
                                                return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
                                            }
                                        },
                                        markers: {
                                            size: 0,
                                            hover: {
                                                sizeOffset: 6
                                            }
                                        },
                                        xaxis: {
                                            categories: ["Mon", "Tue", "Wed", "Thurs", "Fri", "Sat", "Sun"]
                                        },
                                        tooltip: {
                                            y: [
                                                {
                                                    title: {
                                                        formatter: function (val: any) {
                                                            return val
                                                        }
                                                    }
                                                },
                                                {
                                                    title: {
                                                        formatter: function (val: any) {
                                                            return val
                                                        }
                                                    }
                                                },
                                                {
                                                    title: {
                                                        formatter: function (val: any) {
                                                            return val;
                                                        }
                                                    }
                                                }
                                            ]
                                        },
                                        grid: {
                                            borderColor: '#f1f1f1',
                                        }
                                    }}
                                           series={[
                                               {
                                                   name: "Transaction",
                                                   data: weeklyTransactionData
                                               }
                                           ]} type="bar" height={350}/>
                                ) : (
                                    <h2>No Data</h2>
                                )
                            }
                        </div>
                    </Col>
                    <Col md={6}>
                        <h3>Reduced CO2 Emission {selectedYear}</h3>
                        <div className='card-graph-visualization'>
                            {
                                yearlyCo2EmissionReducedData.length > 0 ? (
                                    <Chart options={{
                                        legend: {
                                            tooltipHoverFormatter: function (val: any, opts: any) {
                                                return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
                                            }
                                        },
                                        markers: {
                                            size: 0,
                                            hover: {
                                                sizeOffset: 6
                                            }
                                        },
                                        xaxis: {
                                            categories: [`Jan ${selectedYear}`, `Feb ${selectedYear}`, `Mar ${selectedYear}`, `Apr ${selectedYear}`, `May ${selectedYear}`, `Jun ${selectedYear}`, `Jul ${selectedYear}`, `Aug ${selectedYear}`, `Sep ${selectedYear}`, `Oct ${selectedYear}`, `Nov ${selectedYear}`, `Dec ${selectedYear}`]
                                        },
                                        tooltip: {
                                            y: [
                                                {
                                                    title: {
                                                        formatter: function (val: any) {
                                                            return val
                                                        }
                                                    }
                                                },
                                                {
                                                    title: {
                                                        formatter: function (val: any) {
                                                            return val
                                                        }
                                                    }
                                                },
                                                {
                                                    title: {
                                                        formatter: function (val: any) {
                                                            return val;
                                                        }
                                                    }
                                                }
                                            ]
                                        },
                                        grid: {
                                            borderColor: '#f1f1f1',
                                        }
                                    }}
                                           series={[
                                               {
                                                   name: "CO2 Emission Reduced",
                                                   data: yearlyCo2EmissionReducedData
                                               }
                                           ]} type="bar" height={350}/>
                                ) : (
                                    <h2>No Data</h2>
                                )
                            }
                        </div>
                    </Col>
                </Row>


                <Row>
                    <Col md={6}>
                        <h3>Transaction by Energy</h3>
                        <div className='card-graph-visualization'>
                            {
                                transactionByEnergyLabel.length > 0 ? (
                                    <Chart options={{
                                        legend: {
                                            tooltipHoverFormatter: function (val: any, opts: any) {
                                                return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
                                            }
                                        },
                                        markers: {
                                            size: 0,
                                            hover: {
                                                sizeOffset: 6
                                            }
                                        },
                                        labels: transactionByEnergyLabel,
                                        tooltip: {
                                            y: [
                                                {
                                                    title: {
                                                        formatter: function (val: any) {
                                                            return val
                                                        }
                                                    }
                                                },
                                                {
                                                    title: {
                                                        formatter: function (val: any) {
                                                            return val
                                                        }
                                                    }
                                                },
                                                {
                                                    title: {
                                                        formatter: function (val: any) {
                                                            return val;
                                                        }
                                                    }
                                                }
                                            ]
                                        },
                                        grid: {
                                            borderColor: '#f1f1f1',
                                        }
                                    }}
                                           series={transactionByEnergyData} type="pie" height={350}/>
                                ) : (
                                    <h2>No Data</h2>
                                )
                            }
                        </div>
                    </Col>
                    <Col md={6}>
                        <h3>Transaction by Max Power</h3>
                        <div className='card-graph-visualization'>
                            {
                                transactionByMaxPowerLabel.length > 0 ? (

                                    <Chart options={{
                                        legend: {
                                            tooltipHoverFormatter: function (val: any, opts: any) {
                                                return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
                                            }
                                        },
                                        markers: {
                                            size: 0,
                                            hover: {
                                                sizeOffset: 6
                                            }
                                        },
                                        labels: transactionByMaxPowerLabel,
                                        tooltip: {
                                            y: [
                                                {
                                                    title: {
                                                        formatter: function (val: any) {
                                                            return val
                                                        }
                                                    }
                                                },
                                                {
                                                    title: {
                                                        formatter: function (val: any) {
                                                            return val
                                                        }
                                                    }
                                                },
                                                {
                                                    title: {
                                                        formatter: function (val: any) {
                                                            return val;
                                                        }
                                                    }
                                                }
                                            ]
                                        },
                                        grid: {
                                            borderColor: '#f1f1f1',
                                        }
                                    }}
                                           series={transactionByMaxPowerData} type="pie" height={350}/>

                                ) : (
                                    <h2>No Data</h2>
                                )
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
